import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShoppingCartProvider } from "./components/_default/context/ShoppingCartContext"
import store from "./store/store"
import Redirect from "./components/_default/utilities/Redirect"
import TagManager from 'react-gtm-module'
// Pages
import Webpage2Avaleht from "./pages/Webpage2Avaleht";
import Webpage3Tooted from "./pages/Webpage3Tooted";
import Webpage4Ajurveeda from "./pages/Webpage4Ajurveeda";
import Webpage5KONTAKT from "./pages/Webpage5KONTAKT";
import Webpage6Konsultatsioon from "./pages/Webpage6Konsultatsioon";
import Webpage7Teraapiad from "./pages/Webpage7Teraapiad";
import Webpage8Toode from "./pages/Webpage8Toode";
import Webpage9Ajurveeda_soovitab from "./pages/Webpage9Ajurveeda_soovitab";
import Webpage10M____gitingimused from "./pages/Webpage10M____gitingimused";
import Webpage11Privaatsuspoliitika from "./pages/Webpage11Privaatsuspoliitika";
import Webpage12Soovitused_kevadeks from "./pages/Webpage12Soovitused_kevadeks";
import Webpage13Soovitused_s__giseks from "./pages/Webpage13Soovitused_s__giseks";
import Webpage14Soovitused_talveks from "./pages/Webpage14Soovitused_talveks";
import Webpage15Maksa from "./pages/Webpage15Maksa";
import Webpage16K__psised from "./pages/Webpage16K__psised";
import Webpage17Ajurveeda_kehat____bid from "./pages/Webpage17Ajurveeda_kehat____bid";
import Webpage18Not_found from "./pages/Webpage18Not_found";
import Webpage19Terapeudid from "./pages/Webpage19Terapeudid";
import Webpage20Soovitused_suveks from "./pages/Webpage20Soovitused_suveks";

var baseURL = 'https://my.prana.ee/webstoreApi';
var axiosHeaders = {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiZmVlMWYzY2E5NDM1NzcwN2YxNWM1M2ZiMjRmMjlhYzMzY2U3YjgyMGZhN2ZmZTQxMmZiZjU2OGI2Njc3YmIxZWFlNzhmNTE1NWFmMjM3YmIiLCJpYXQiOjE3MjY1NzUxNDMuOTk1OTQxLCJuYmYiOjE3MjY1NzUxNDMuOTk1OTQ3LCJleHAiOjE3MjY2NjE1NDMuOTkxNDk4LCJzdWIiOiI2NCIsInNjb3BlcyI6W119.D0pbb-XE39BOD8gbDrhlUTUJxYGrLOyfqoyLP8OIDVgDGkjD_TDXksa7EqZ54L8BFoJ2nwWR8OIkMEL9MrsmBh8EQMVXKJyJXyvARIgb0hsA0Dm_14qtDlbjR_KbXFhkDV_1XQJodW1vt-rAADE74ydkkUXE2T3ecNtimt1pOi3gFZugHSROU06l3zmO-29Zst0lNPt-d_8MJdel8GKuMce6qqIKPzCraEC-sOD0TwzKNO35ScExb-VN-ILeRAsX-GB9VHT8g7XXDJ1_1vrRQ1I27nizAgAreoJW-xkTwJs7N123YIHLXpSnpcGhamTpVoF5a8OiC7mtFbWzak81cuuqZ88YyKo4LfmJOJwtYIRKseNqOyaOZDf3XvwZueRzyReqGtLONoQw9_6Fyn4TatcERYCCn6KO_ZaIf4RZxf1OivynwD_taYviZV4635IRRIbt7NUXUbj-Pu2VmiVLFfd1BzJ9cihkCsdjMBeWP6XMQnuyAME6Of_cWGqkxVsKr92DSdIgA5IDWQGoEzJvTUQqcVu106OjD2lweKtYn3b4-T6lSBIYoisXrm6jphBbC77q-eEjqmbnWeKRRAlhIYasSypUkHZS0LqErPsgSKlmI85lrNUfc2w1zETy1LZS3VCpZeCaYd1_PpvYdrx3rz4ZJVZ8IzfKvoe9Qr-VGf0',
    'Access-api-key': "eyJpdiI6IkJHaXV6RXlZQ0xSaWJOaE9HVkFncEE9PSIsInZhbHVlIjoiQ2NRcU9IVEFVSW5nRDVuNjBVdkQ0UT09IiwibWFjIjoiNDdhMTAyZDc5ZDEwYmQyOTBjNTMzNGFlODFkMDgyYWQ5MzNiODQ5YjdhZGU2ODA1YzQyNzk0NDU2YmU2ZjQ0YiIsInRhZyI6IiJ9",
    'Access-api-id': "eyJpdiI6ImNrYzIzbTRBU2UxcWRlOWRWamhROVE9PSIsInZhbHVlIjoiUWNxaExZdW5yNmNINWdIRjRXckhIUT09IiwibWFjIjoiMjg1ZDAxODZjN2ZmNDQ5MmJjODdkZTE4YzY4YWY5NDE0N2U1YTQ2NzRiNmI4ZTRmYmQ3MDllN2E5YTlkY2QzMiIsInRhZyI6IiJ9",
    'Access-Control-Allow-Origin': "*"
 };


window.axios = require('axios').create({
  baseURL: baseURL,
  headers: axiosHeaders
});
const tagManagerArgs = {
    gtmId: "GTM-NDMXWK5"
}
TagManager.initialize(tagManagerArgs)
    
export default function App() {
    window.dataLayer.push({
        event: 'pageview'
    });
    return (
        <Provider store={store}>
            <ShoppingCartProvider>
                <BrowserRouter basename="/">
                    <Routes>
                                            <Route path="/et/" element={<Webpage2Avaleht />} exact />;
                                                                    <Route path="/et/tooted" element={<Webpage3Tooted />} exact />;
                                                                    <Route path="/et/ayurveda" element={<Webpage4Ajurveeda />} exact />;
                                                                    <Route path="/et/contact" element={<Webpage5KONTAKT />} exact />;
                                                                    <Route path="/et/consultation" element={<Webpage6Konsultatsioon />} exact />;
                                                                    <Route path="/et/therapy" element={<Webpage7Teraapiad />} exact />;
                                                                    <Route path="/et/toode/:id" element={<Webpage8Toode />} exact />;
                                                                    <Route path="/et/ayurveda/recommend" element={<Webpage9Ajurveeda_soovitab />} exact />;
                                                                    <Route path="/et/terms" element={<Webpage10M____gitingimused />} exact />;
                                                                    <Route path="/et/privacy" element={<Webpage11Privaatsuspoliitika />} exact />;
                                                                    <Route path="/et/ayurveda/seasonalSpring" element={<Webpage12Soovitused_kevadeks />} exact />;
                                                                    <Route path="/et/ayurveda/seasonalAutumn" element={<Webpage13Soovitused_s__giseks />} exact />;
                                                                    <Route path="/et/ayurveda/seasonalWinter" element={<Webpage14Soovitused_talveks />} exact />;
                                                                    <Route path="/et/checkout" element={<Webpage15Maksa />} exact />;
                                                                    <Route path="/et/cookiePolicy" element={<Webpage16K__psised />} exact />;
                                                                    <Route path="/et/ayurveda/bodytypes" element={<Webpage17Ajurveeda_kehat____bid />} exact />;
                                                                    <Route path="/et/*" element={<Webpage18Not_found />} exact />;
                                                <Route path="*" element={<Webpage18Not_found />} exact />;
                                                                    <Route path="/et/terapeudid" element={<Webpage19Terapeudid />} exact />;
                                                                    <Route path="/et/ayurveda/seasonalSummer" element={<Webpage20Soovitused_suveks />} exact />;
                                                                
                                            <Route path="/product/:id" element={<Redirect to="/toode/:id" />} exact />;
                                            <Route path="/products" element={<Redirect to="/tooted" />} exact />;
                                                            </Routes>
                </BrowserRouter>
            </ShoppingCartProvider>
        </Provider>
    );
}